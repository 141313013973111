import styled from '@emotion/styled';
import _ from 'lodash';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { FaCaretRight, FaGripHorizontal, FaTimes } from 'react-icons/fa';
import { SHOW_COMMEMORATE } from 'src/constants';
import { APP_IMG } from 'src/constants/index';
import { IExpandToggle, IMenu } from 'src/interface/layout';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { getWidthDimension, splitRote } from 'src/utils/helper';

const CardVNav = dynamic(import('src/components/cards/card-v-nav'));
const WidgetSearchFullPage = dynamic(import('src/components/widget/widget-search-full-page'));
const WidgetLogin = dynamic(import('src/services/member-auth/components/widget-login'));

type Props = any & {
  _data: IMenu[];
  eventDataLayer: any;
};
const NavDesktop: NextPage<Props> = ({ eventDataLayer, _data, sessionSevId }) => {
  const moreMenuRef: any = useRef();
  const [moreMenu, setMoreMenu] = useState(true);
  // ---------- expand sub-menu ----------
  const [subToggleMoreMenu, setSubToggleMoreMenu] = useState<IExpandToggle>({
    status: false,
    key: null
  });

  const [subToggle, setSubToggle] = useState<IExpandToggle>({
    status: false,
    key: null
  });
  const [widthDimension, setWidthDimension] = useState(getWidthDimension());
  const router: NextRouter = useRouter();

  const cid: string = router.asPath;
  const queryCid: string | string[] | undefined = router.query.cid;
  const [articles, setArticles]: any = useState({
    key: null,
    data: null,
    link: null
  });

  let _results: any = [];

  if (widthDimension?.width < 1200) {
    _data = [..._data];
    _results = _data.splice(0, 6);
  } else {
    _data = [..._data];
    _results = _data.splice(0, 8);
  }

  useEffect(() => {
    function handleResize() {
      setWidthDimension(getWidthDimension());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [router.isReady]);

  return (
    <>
      <NavWrapper className='nav-desktop'>
        <SideBarWrapper id='side-bar'>
          <ul className='side-nav-menu' id='side-nav-menu' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
            <li className={`${cid === '/' ? '--active' : ''} listItem`}>
              <a
                href={`${splitRote(router)}/`}
                aria-label='หน้าแรก'
                title='หน้าแรก'
                // ! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    ...eventDataLayer,
                    data: {
                      title: 'หน้าแรก',
                      heading: 'หน้าแรก'
                    }
                  })
                }
              >
                <FaCaretRight size={16} />
                <span> หน้าแรก </span>
              </a>
            </li>
            {!_.isEmpty(_results) &&
              _results?.map((element: any, index: any) => (
                <li
                  className={`${cid === `/${element?.link}` || queryCid === element?.nameEng ? '--active' : ''} listItem`}
                  key={index}
                  onMouseLeave={() => setSubToggle({ status: false, key: null })}
                >
                  <a
                    href={`${splitRote(router)}/${element?.link}`}
                    onMouseOver={() => {
                      setSubToggle({ status: true, key: index });
                      setArticles({
                        key: index,
                        data: element?.sub[0]?.data,
                        link: element?.sub[0]?.link
                      });
                    }}
                    onFocus={() => { }}
                    title={element?.nameTh}
                    aria-label={element?.nameTh}
                    // ! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        ...eventDataLayer,
                        data: {
                          title: element?.nameTh,
                          heading: element?.nameTh,
                          index: index + 2
                        }
                      })
                    }
                  >
                    {element.sub.length > 0 && <FaCaretRight size={16} />}
                    <span>{element?.nameTh}</span>
                  </a>
                  {subToggle?.key === index && subToggle?.status && element?.sub.length > 0 && (
                    <div
                      className='nav-sub-menu --left'
                      onMouseLeave={() => {
                        setSubToggle({ key: null, status: false });
                      }}
                    >
                      <CardSubMenuStyle>
                        <div className='card-sub-menu'>
                          <div className='block-1'>
                            <ul>
                              {!_.isEmpty(element?.sub) &&
                                element.sub.map((subMenu: any, i: any) => (
                                  <li
                                    key={i}
                                    aria-hidden='true'
                                    onMouseOver={() => {
                                      setArticles({
                                        key: i,
                                        data: subMenu?.data,
                                        link: subMenu?.link
                                      });
                                    }}
                                    onFocus={() => { }}
                                  >
                                    <a
                                      href={`${splitRote(router)}${subMenu?.link}`}
                                      title={subMenu.nameTh}
                                      // ! DATALAYER
                                      onClick={() =>
                                        sendDataLayerGlobal({
                                          ...eventDataLayer,
                                          position: 'ใน:menubar',
                                          data: {
                                            title: subMenu?.nameTh,
                                            heading: `${element?.nameTh}:${subMenu?.nameTh}`,
                                            index: i + 1
                                          }
                                        })
                                      }
                                    >
                                      {subMenu?.nameTh}
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className='block-2'>
                            {!_.isEmpty(articles?.data) ? (
                              <>
                                <div className='card-list'>
                                  {articles.data.slice(0, 4).map((item: any, i: any) => (
                                    <div key={i} className={`item-${i + 1}`}>
                                      <CardVNav
                                        data={item}
                                        showBlurb={false}
                                        showBadge={false}
                                        showCountView={false}
                                        // ! DATALAYER
                                        eventDataLayer={{
                                          ...eventDataLayer,
                                          position: 'ใน:menubar',
                                          data: {
                                            heading: `${element?.nameTh}:${element.sub[i]?.nameTh}`
                                          }
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                                <div className='btn-readmore'>
                                  <a className='see-all' href={articles?.link} title='see all'>
                                    {`>>> ดูทั้งหมด`}
                                  </a>
                                </div>
                              </>
                            ) : (
                              <div className='empty'>
                                <img className='logo-img' src={`${APP_IMG}/cache/default.webp`} loading='lazy' alt='logo-mobile' title='logo-mobile' width='100px' height='100px' />
                              </div>
                            )}
                          </div>
                        </div>
                      </CardSubMenuStyle>
                    </div>
                  )}
                </li>
              ))}
          </ul>
          <div className='area-more-menu'>
            {_data.length > 0 && (
              <>
                <ul className='more-menu' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
                  {_results.length > 0 &&
                    (moreMenu ? (
                      <li onClick={() => setMoreMenu(false)}>
                        <FaGripHorizontal size={24} />
                      </li>
                    ) : (
                      <li onClick={() => setMoreMenu(true)}>
                        <FaTimes size={18} />
                      </li>
                    ))}
                </ul>

                <div className={`expand-more-menu ${moreMenu ? '--hide' : ''}`} ref={moreMenuRef} style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
                  <ul className='expand-nav-menu'>
                    {_data.map((element: any, i: number) => (
                      <li key={i} className={`${cid === `/${element?.link}` || queryCid === element?.nameEng ? '--active' : ''} listItem`}>
                        {element.sub.length > 0 ? (
                          <div
                            key={i}
                            onMouseOver={() => {
                              // setSubToggleMoreMenu({ status: true, key: i });
                              // setExpandToggle({ status: false, key: null });
                              setSubToggleMoreMenu({ status: true, key: i });
                              setArticles({
                                key: i,
                                data: element?.sub[0]?.data,
                                link: element?.sub[0]?.link
                              });
                            }}
                            onMouseLeave={() => setSubToggleMoreMenu({ key: null, status: false })}
                          >
                            {element.sub.length > 0 && <FaCaretRight size={16} />}

                            <a
                              href={`${splitRote(router)}/${element?.link}`}
                              // ! DATALAYER
                              onClick={() =>
                                sendDataLayerGlobal({
                                  ...eventDataLayer,
                                  position: 'ใน:expanded-menubar',
                                  data: {
                                    title: element?.nameTh,
                                    heading: `${element?.nameTh}`,
                                    index: i + 1
                                  }
                                })
                              }
                            >
                              <span> {element.nameTh}</span>
                            </a>

                            {subToggleMoreMenu.status && subToggleMoreMenu.key === i && (
                              <div
                                className='nav-sub-menu --left'
                                onMouseLeave={() => {
                                  setSubToggle({ key: null, status: false });
                                }}
                              >
                                <CardSubMenuStyle>
                                  <div className='card-sub-menu'>
                                    <div className='block-1'>
                                      <ul>
                                        {!_.isEmpty(element?.sub) &&
                                          element.sub.map((subMenu: any, i: any) => (
                                            <li
                                              key={i}
                                              // className='listItem'
                                              aria-hidden='true'
                                              onMouseOver={() => {
                                                setArticles({
                                                  key: i,
                                                  data: subMenu?.data,
                                                  link: subMenu?.link
                                                });
                                              }}
                                              onFocus={() => { }}
                                            >
                                              <a
                                                href={`${splitRote(router)}${subMenu?.link}`}
                                                title={subMenu.nameTh}
                                                // ! DATALAYER
                                                onClick={() =>
                                                  sendDataLayerGlobal({
                                                    ...eventDataLayer,
                                                    position: 'ใน:expanded-menubar',
                                                    data: {
                                                      title: subMenu?.nameTh,
                                                      heading: `${element?.nameTh}:${subMenu?.nameTh}`,
                                                      index: i + 1
                                                    }
                                                  })
                                                }
                                              >
                                                {subMenu?.nameTh}
                                              </a>
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                    <div className='block-2'>
                                      {!_.isEmpty(articles?.data) ? (
                                        <>
                                          <div className='card-list'>
                                            {articles.data.slice(0, 4).map((item: any, index: any) => (
                                              <div className={`item-${i + 1}`}>
                                                <CardVNav
                                                  data={item}
                                                  showBlurb={false}
                                                  showBadge={false}
                                                  showCountView={false}
                                                  // ! DATALAYER
                                                  eventDataLayer={{
                                                    ...eventDataLayer,
                                                    position: 'ใน:expanded-menubar',
                                                    data: {
                                                      heading: `${element?.nameTh}:${element.sub[index]?.nameTh}`
                                                    }
                                                  }}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                          <div className='btn-readmore'>
                                            <a className='see-all' href={articles?.link} title='see all'>
                                              {`>>> ดูทั้งหมด`}
                                            </a>
                                          </div>
                                        </>
                                      ) : (
                                        <div className='empty'>
                                          <img className='logo-img' src={`${APP_IMG}/cache/default.webp`} loading='lazy' alt='logo-mobile' title='logo-mobile' width='100px' height='100px' />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </CardSubMenuStyle>
                              </div>
                            )}
                          </div>
                        ) : (
                          <a
                            href={`${splitRote(router)}/${element?.link}`}
                            // ! DATALAYER
                            onClick={() =>
                              sendDataLayerGlobal({
                                ...eventDataLayer,
                                position: 'ใน:expanded-menubar',
                                data: {
                                  title: element?.nameTh,
                                  heading: `${element?.nameTh}`,
                                  index: i + 1
                                }
                              })
                            }
                          >
                            {element?.nameTh}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            <WidgetSearchFullPage />
            <WidgetLogin sessionSevId={sessionSevId} />
          </div>
        </SideBarWrapper>
      </NavWrapper>
    </>
  );
};
const NavWrapper = styled.div`
  .area-more-menu {
    display: flex;
    align-items: center;
    .more-menu {
      padding: 12px;
      li {
        display: flex;
        align-items: center;
        &:hover svg {
          color: var(--tertiary-color);
        }
      }
      svg {
        cursor: pointer;
        color: var(--text-hover);
      }
    }
  }
  .expand-more-menu {
    position: absolute;
    bottom: -40px;
    left: 0;
    height: 40px;
    width: 100%;
    background: var(--background-color);
    box-shadow: 0px 5px 6px -6px rgba(0, 0, 0, 0.3);

    z-index: 6;
    @media (max-width: 1025px) {
      padding: 0px 20px;
    }
    .expand-nav-menu {
      li > div,
      .listItem {
        a {
          color: var(--black-color);
          font-weight: 400;
          font-size: 16px;
          margin-left: 5px;
        }
        svg {
          color: var(--tertiary-color);
          margin-left: 5px;
          vertical-align: middle;
        }
        &:hover {
          a,
          svg {
            color: var(--tertiary-color);
          }
        }
      }
      padding: 5px 0px;
      @media (max-width: 769px) {
        padding: 5px 20px;
      }
    }

    ul {
      max-width: 1270px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style-type: none;
      position: relative;
      gap: 20px;
    }

    &.--hide {
      display: none;
    }
  }
`;
const CardSubMenuStyle = styled.div`
  position: relative;
  .card-sub-menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'block-1 block-2 block-2 block-2 block-2 block-2';
    grid-gap: 20px;
    padding: 20px;
    .block-1 {
      grid-area: block-1;
      ul li a {
        color: var(--tertiary-color) !important;
        &:hover {
          color: var(--text-hover) !important;
        }
      }
    }
    .block-2 {
      grid-area: block-2;
      .card-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
        .card-content {
          padding: 0px;
          h3 {
            line-height: 1.55;
          }
        }
      }
      .btn-readmore {
        width: 100%;
        text-align: right;
        margin-top: 10px;
        .see-all {
          opacity: 0.85;
          color: var(--gray-color);
          &:hover {
            color: var(--tertiary-color);
          }
        }
        a {
          opacity: 0.85;
          color: var(--gray-color);
          &:hover {
            color: var(--tertiary-color);
          }
        }
      }
    }
    ul {
      overflow: hidden scroll;
      height: 200px;
      list-style: none;
      padding: 0;
      display: block;
      &::-webkit-scrollbar {
        width: 4px;
        background-color: rgb(0, 0, 0, 0.2);
        border-radius: 15px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgb(247, 247, 247, 0.2);
        border-radius: 15px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background-color: var(--tertiary-color);
      }

      li {
        padding: 5px;
        border-radius: 15px;
        margin-bottom: 16px;
        margin-left: 0;
        text-align: left;
        border: 2px;
        transition: all 0.3s ease-out;
        i {
          float: right;
          color: transparent;
        }

        a {
          color: var(--tertiary-color);
        }
        &:hover {
          padding-left: 16px;
        }
      }
    }
  }
`;

const SideBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .side-nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .svg-tag {
      cursor: pointer;
      color: var(--tertiary-color);
      width: 30px;
      height: 30px;
      path {
        stroke: var(--tertiary-color);
      }
    }
  }
  .side-nav-menu {
    position: relative;
    list-style: none;
    display: flex;
    transition: opacity 1s ease-in-out;

    li.listItem {
      display: flex;
      align-items: center;
      padding: 0px 10px;
      height: 54px;

      .nav-sub-menu {
        position: absolute;
        top: 54px;
        overflow: visible;
        a {
          font-weight: 400;
          font-size: 16px;
          color: var(--tertiary-color);
          &:hover {
            color: var(--tertiary-color);
          }
        }
      }
      span {
        color: var(--black-color);
        font-weight: 500;
        font-size: 15px;
        vertical-align: middle;
        text-transform: capitalize;
        margin-left: 5px;

        @media (max-width: 1024px) {
          font-size: 14px;
        }
        @media (max-width: 900px) {
          font-size: 12px;
        }
      }
      svg {
        vertical-align: middle;
        color: var(--tertiary-color);
        margin-left: 5px;
        @media (max-width: 960px) {
          display: none;
        }
      }

      &:hover {
        z-index: 7;
        span,
        svg {
          color: var(--text-hover);
        }
      }
      &.--active {
        span {
          &:hover {
            color: var(--tertiary-color);
          }
          color: var(--black-color);
        }
        svg {
          color: var(--tertiary-color);
        }
      }
    }
  }
  .nav-sub-menu {
    background: var(--white-color);
    width: 1200px;
    position: absolute;
    visibility: visible;
    z-index: 5;
    padding: 0px;
    margin: 0px;
    opacity: 1;
    transition: all 0.3s ease-out;
    transform-origin: 0 0 0;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    a {
      display: block;
      transition: all 0.25s ease-out;
    }
    &.--left {
      left: 0;
    }
  }
`;
export default NavDesktop;
